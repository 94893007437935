var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { ImpossibilidadeResposta } from '@app/modules/routine-checklist/impossibilidade-resposta';
import { FlexRow } from '@app/modules/routine-checklist/quick-checklist/styles';
import { ActivityIndicator, FlashMessageEnum } from '@atomic';
import { ChecklistCard, CheckTypes } from '../checklist-card';
import { LoadMoreInfo, NumberCircle } from './checklists-list.styled';
export var ChecklistsAccordion = function (_a) {
    var type = _a.type, checklistData = _a.checklistData;
    var showFlashMessage = useFlashMessage()[0];
    var _b = useState(false), isLoadingMore = _b[0], setLoadingMore = _b[1];
    var _c = useState([]), checklists = _c[0], setChecklists = _c[1];
    var _d = useState(4), defaultCardQuantity = _d[0], setDefaultCardQuantity = _d[1];
    var _e = useState([]), checklistLoaded = _e[0], setChecklistLoaded = _e[1];
    var handleExpansion = function (idx) {
        var data = checklistLoaded.map(function (value, index) {
            if (index === idx) {
                value.expanded = !value.expanded;
            }
            return value;
        });
        setChecklistLoaded(data);
    };
    var loadMore = function (idx, checkData) { return __awaiter(void 0, void 0, void 0, function () {
        var pageNumber, pageSize, url, resp, data;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    setLoadingMore(true);
                    pageNumber = ((_b = (_a = checkData === null || checkData === void 0 ? void 0 : checkData.data) === null || _a === void 0 ? void 0 : _a.pageNumber) !== null && _b !== void 0 ? _b : 0) + 1;
                    pageSize = (_d = (_c = checkData === null || checkData === void 0 ? void 0 : checkData.data) === null || _c === void 0 ? void 0 : _c.pageSize) !== null && _d !== void 0 ? _d : 10;
                    url = checkData.url + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
                    return [4 /*yield*/, axiosClient.get(url)];
                case 1:
                    resp = _g.sent();
                    data = (_e = resp.data) === null || _e === void 0 ? void 0 : _e.data;
                    (_f = data === null || data === void 0 ? void 0 : data.results) === null || _f === void 0 ? void 0 : _f.forEach(function (item) { return (item.type = checkData.type); });
                    setChecklistLoaded(checklistLoaded.map(function (value, index) {
                        var _a;
                        var _b;
                        if (index === idx) {
                            value.data = data;
                            (_a = value.checklists).push.apply(_a, ((_b = data === null || data === void 0 ? void 0 : data.results) !== null && _b !== void 0 ? _b : []));
                        }
                        return value;
                    }));
                    setLoadingMore(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var _f = useQuery(['get-checklists', type], function () { return __awaiter(void 0, void 0, void 0, function () {
        var _loop_1, i, err_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 5, , 6]);
                    _loop_1 = function (i) {
                        var resp, data;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, axiosClient.get(checklistData[i].url)];
                                case 1:
                                    resp = _a.sent();
                                    data = (_a = resp.data) === null || _a === void 0 ? void 0 : _a.data;
                                    (_b = data === null || data === void 0 ? void 0 : data.results) === null || _b === void 0 ? void 0 : _b.forEach(function (item) { return (item.type = checklistData[i].type); });
                                    checklistData[i].data = data;
                                    // checklistData[i].expanded = true;
                                    checklistData[i].checklists = (_c = data === null || data === void 0 ? void 0 : data.results) !== null && _c !== void 0 ? _c : [];
                                    return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _d.label = 1;
                case 1:
                    if (!(i < checklistData.length)) return [3 /*break*/, 4];
                    return [5 /*yield**/, _loop_1(i)];
                case 2:
                    _d.sent();
                    _d.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    setChecklistLoaded(checklistData);
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _d.sent();
                    showFlashMessage(FlashMessageEnum.alert, 'Erro ao carregar Histórico de Checklists');
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: true,
    }), isLoading = _f.isLoading, isFetching = _f.isFetching, refetch = _f.refetch;
    if (type === CheckTypes.PROGRESS_UNIT_TYPE) {
        checklists === null || checklists === void 0 ? void 0 : checklists.sort(function (a, b) {
            // Certifique-se de que 'dataInicioResposta' seja uma data válida
            var dateA = new Date(a.dataInicioResposta);
            var dateB = new Date(b.dataInicioResposta);
            // Use a função 'getTime()' para comparar as datas
            return dateB.getTime() - dateA.getTime();
        });
    }
    var handleCardQuantityRender = function (height) {
        if (height < 400) {
            setDefaultCardQuantity(1);
        }
        else if (height < 500) {
            setDefaultCardQuantity(2);
        }
        else if (height < 650) {
            setDefaultCardQuantity(2);
        }
        else if (height < 780) {
            setDefaultCardQuantity(3);
        }
        else if (height < 820) {
            setDefaultCardQuantity(4);
        }
        else {
            setDefaultCardQuantity(5);
        }
    };
    useEffect(function () {
        handleCardQuantityRender(window.innerHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerHeight]);
    var _g = useState(false), openImpossibilita = _g[0], setOpenImpossibilita = _g[1];
    var _h = useState(false), impossibilitaChecklist = _h[0], setImpossibilitaChecklist = _h[1];
    var handleImpossibilitarRespostaChecklist = function (e, checklist) {
        e.stopPropagation();
        setImpossibilitaChecklist(checklist);
        setOpenImpossibilita(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ImpossibilidadeResposta, { open: openImpossibilita, onClose: function () { return setOpenImpossibilita(false); }, checklist: impossibilitaChecklist, checklistImpossibilitado: refetch }),
        React.createElement("div", { style: { marginTop: '25px' } }, checklistLoaded === null || checklistLoaded === void 0 ? void 0 : checklistLoaded.map(function (checkData, index) {
            var _a, _b, _c, _d, _e;
            return (React.createElement(Accordion, { key: index, expanded: checkData.expanded, onChange: function () { return handleExpansion(index); }, sx: {
                    '& .MuiAccordion-region': { height: checkData.expanded ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: checkData.expanded ? 'block' : 'none' },
                    '& .MuiAccordion-root': { position: 'unset' },
                }, style: {
                    border: '1px solid #D9D9D9',
                    borderTopLeftRadius: index == 0 ? '16px' : '0px',
                    borderTopRightRadius: index == 0 ? '16px' : '0px',
                    borderBottomLeftRadius: index == checklistLoaded.length - 1 ? '16px' : '0px',
                    borderBottomRightRadius: index == checklistLoaded.length - 1 ? '16px' : '0px',
                } },
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                    React.createElement("div", { style: { display: 'flex', alignItems: 'center', columnGap: '10px' } },
                        React.createElement(Typography, null, checkData.title),
                        React.createElement(NumberCircle, null, (_a = checkData.data) === null || _a === void 0 ? void 0 : _a.totalRecords))),
                React.createElement(AccordionDetails, { style: { padding: '0px 0px 0px' } }, checkData === null || checkData === void 0 ? void 0 :
                    checkData.checklists.map(function (checklist, idx) {
                        return idx < (checkData === null || checkData === void 0 ? void 0 : checkData.checklists.length) && (React.createElement(ChecklistCard, { key: "" + checklist.id + idx, checklist: checklist, isFirst: false, isLast: false, type: checklist.type, onClickImpossibilitar: handleImpossibilitarRespostaChecklist }));
                    }),
                    !isLoadingMore && ((_b = checkData === null || checkData === void 0 ? void 0 : checkData.data) === null || _b === void 0 ? void 0 : _b.pageNumber) < ((_c = checkData === null || checkData === void 0 ? void 0 : checkData.data) === null || _c === void 0 ? void 0 : _c.totalPages) && (React.createElement(LoadMoreInfo, { onClick: function () { return loadMore(index, checkData); }, style: { padding: '10px' } }, 'Ver mais')),
                    isLoadingMore && ((_d = checkData === null || checkData === void 0 ? void 0 : checkData.data) === null || _d === void 0 ? void 0 : _d.pageNumber) < ((_e = checkData === null || checkData === void 0 ? void 0 : checkData.data) === null || _e === void 0 ? void 0 : _e.totalPages) && (React.createElement(FlexRow, { style: { width: '100%', alignItems: 'center', justifyContent: 'center', padding: '10px' } },
                        React.createElement(ActivityIndicator, { type: "spinner", size: "1x" }))))));
        })),
        isLoading || isFetching ? (React.createElement(FlexRow, { style: { width: '100%', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(ActivityIndicator, { type: "spinner", size: "2x" }))) : null));
};
